import React from 'react'
import KnowledgeService from 'src/knowledge/service'
import { navigate } from 'gatsby'

export default function PageTemplate(props) {
  let variantPath = [''].concat(KnowledgeService.currentVariantPath).join('/')
  let path = variantPath + props.path

  React.useLayoutEffect(() => { navigate(path) }, [path])
  return null
}
